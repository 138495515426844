import axios from "axios";
import { profile } from "./url";

const getAllProfile = (body) => {
  return axios.post(profile.LIST, body);
};
const addProfile = (body) => {
  return axios.post(profile.ADD, body);
};
const deleteProfile = (id) => {
  return axios.post(profile.DELETE + id);
};
const getParticularProfile = (id) => {
  return axios.get(profile.PARTICULAR + id);
};
const adminGetParticularProfile = (id) => {
  return axios.get(profile.ADMIN_PARTICULAR + id);
};
const updateProfile = (data) => {
  return axios.post(profile.UPDATE, data);
};

const ProfileService = {
  getAllProfile,
  deleteProfile,
  addProfile,
  getParticularProfile,
  adminGetParticularProfile,
  updateProfile,
};

export default ProfileService;
