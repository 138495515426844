import React, { useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login.css";
import AuthService from "../../service/auth-service";
import { useHistory } from "react-router-dom";
import TokenInterceptor from "../../service/token-interceptor";

const Login = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = (values) => {
    setIsLoading(true);
    AuthService.login(values)
      .then((res) => {
        setIsLoading(false);
        TokenInterceptor(res?.data?.token);
        localStorage.setItem(
          "token",
          JSON.stringify(`Bearer ${res?.data?.token}`)
        );
        history.push("/");
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <Row className="login-main-div">
      <Col span={8} offset={8}>
        <h1 style={{ textAlign: "center" }}>Vasantham Matrimony</h1>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
          initialValues={{
            username: "",
            password: "",
          }}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            style={{ marginTop: 10 }}
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              disabled={isLoading}
              loading={isLoading}
              style={{ width: "100%", marginTop: 10, height: 40 }}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Login;
