import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import Login from "../screens/login/Login";
import ManageProfile from "../screens/ManageProfile/ManageProfile";
import AddProfile from "../screens/AddProfile/AddProfile";
import EditProfile from "../screens/EditProfile/EditProfile";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/login" component={Login} exact />
        <PrivateRoute path="/" component={ManageProfile} exact />
        <PrivateRoute path="/addprofile" component={AddProfile} />
        <PrivateRoute path="/editprofile/:id" component={EditProfile} />
        <Redirect from="*" to={"/"} />
      </Switch>
    </>
  );
};

export default Routes;
