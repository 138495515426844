import {
  DeleteOutlined,
  EditOutlined,
  LogoutOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect, useRef } from "react";
import AuthService from "../../service/auth-service";
import "./ManageProfile.css";
import { Button, Input, message, Modal, Popconfirm, Space, Table } from "antd";
import ProfileService from "../../service/profile-service";
import Highlighter from "react-highlight-words";
import { useHistory } from "react-router-dom";

const ManageProfile = () => {
  const { confirm } = Modal;
  const history = useHistory();
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);
    ProfileService.getAllProfile()
      .then((res) => {
        setIsLoading(false);
        setProfiles(res?.data?.data || []);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const deleteProfile = (data) => {
    ProfileService.deleteProfile(data?.id).then((res) => {
      message.success("Profile deleted succesfully!");
      fetchData();
    });
  };

  const handleSearch = (selectedKeys, confirm = () => {}, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (selectedKeys, confirm = () => {}, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(0);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      ...getColumnSearchProps("id"),
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (data) => (
        <img
          src={data}
          style={{
            height: 50,
            width: 50,
            objectFit: "cover",
            objectPosition: "top",
            borderRadius: 4,
          }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Status",
      dataIndex: "jathakathin_nellai",
      key: "jathakathin_nellai",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Broker",
      dataIndex: "broker",
      key: "broker",
    },
    {
      title: "Action",
      align: "center",
      render: (text) => (
        <div>
          <Popconfirm
            title="Are you sure edit this profile?"
            okText="Yes"
            cancelText="No"
            placement="left"
            onConfirm={() => editProfile(text)}
          >
            <EditOutlined style={{ marginRight: 10 }} />
          </Popconfirm>
          <Popconfirm
            title="Are you sure delete this profile?"
            okText="Yes"
            cancelText="No"
            placement="left"
            onConfirm={() => deleteProfile(text)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const showLogoutConfirm = () => {
    confirm({
      title: "Are you sure to logout?",
      okText: "Yes",
      okType: "default",
      cancelText: "No",
      onOk() {
        AuthService.logout();
      },
    });
  };

  const editProfile = (data) => {
    history.push("/editprofile/" + data?.id);
  };

  return (
    <div className="manageprofile-wrapper">
      <div className="add-btn-div">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size={20}
          onClick={() => history.push("addprofile")}
        >
          Add Profile
        </Button>
        <Button
          type="primary"
          id="logout-btn"
          icon={<LogoutOutlined />}
          size={20}
          onClick={() => showLogoutConfirm()}
        >
          Logout
        </Button>
      </div>
      <Table
        dataSource={profiles}
        columns={columns}
        loading={isLoading}
        pagination={{
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

export default ManageProfile;
